import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import logoSm from "../../assets/icons/logo-1.jpg";
import Loading from "../../components/common/Loading";
import { ReadCountriesService } from "../../services/general";
import { ReadLoyaltyEarnQRService, ValidateLoyaltyEarnQRService } from "../../services/loyalty";
import "./earnqrscan.css";
var Messages = require('./EarnQRScanMessages.json');

const EarnQRScan = () => {
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        mobile: "",
        countryCode: ""
    });
    const [formErrors, setFormErrors] = useState({
        mobile: "",
        countryCode: "",
        otherError: "",
        otherArabicError: ""
    });
    const [qrCodeDetail, setQRCodeDetail] = useState({});
    const [messages, setMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "mobile" && value.startsWith("0")) {
            return;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const countryCodeError = formData.countryCode ? "" : "Country Code cannot be Blank";
        const mobileError = formData.mobile ? "" : "Mobile cannot be Blank";
        setFormErrors({
            countryCode: countryCodeError,
            mobile: mobileError
        });

        if (!countryCodeError && !mobileError) {
            setLoading(true);
            let mobile = '+' + formData.countryCode.split('_')[0] + formData.mobile;
            let temp_validate_response = await ValidateLoyaltyEarnQRService(params.token, formData.countryCode.split('_')[1], mobile, false);
            if (temp_validate_response.success) {
                toast.success(messages.successfully_earned.english.replace('{temp_validate_response.data.points}', temp_validate_response.data.points));
                toast.success(messages.successfully_earned.arabic.replace('{temp_validate_response.data.points}', temp_validate_response.data.points));
                navigate("/");
            } else {
                if (temp_validate_response.data.already_validated) {
                    if (temp_validate_response.data.status === 'failed'){
                        setFormErrors((prev) => ({ ...prev,
                            otherError: messages.already_validated_failed.english,
                            otherArabicError: messages.already_validated_failed.arabic
                        }));
                    }else if (temp_validate_response.data.status === 'done'){
                        setFormErrors((prev) => ({ ...prev,
                            otherError: messages.already_validated_done.english,
                            otherArabicError: messages.already_validated_done.arabic
                        }));
                    }
                } else if (temp_validate_response.data.customer_not_exists) {
                    let is_confirmed = window.confirm(`${messages.customer_not_exists.english} ${messages.customer_not_exists.arabic}`);
                    if (is_confirmed){
                        let temp_validate_response = await ValidateLoyaltyEarnQRService(params.token, formData.countryCode.split('_')[1], mobile, true);
                        if (temp_validate_response.success) {
                            toast.success(messages.successfully_earned.english.replace('{temp_validate_response.data.points}', temp_validate_response.data.points));
                            toast.success(messages.successfully_earned.arabic.replace('{temp_validate_response.data.points}', temp_validate_response.data.points));
                            navigate("/thank-you");
                        } else {
                            if (temp_validate_response.data.already_validated) {
                                if (temp_validate_response.data.status === 'failed'){
                                    setFormErrors((prev) => ({ ...prev,
                                        otherError: messages.already_validated_failed.english,
                                        otherArabicError: messages.already_validated_failed.arabic
                                    }));
                                }else if (temp_validate_response.data.status === 'done'){
                                    setFormErrors((prev) => ({ ...prev,
                                        otherError: messages.already_validated_done.english,
                                        otherArabicError: messages.already_validated_done.arabic
                                    }));
                                }
                            }
                        }
                    }else{
                        navigate("/");
                    }
                }
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            setMessages(Messages);
            let temp_countries = await ReadCountriesService();
            if (temp_countries) {
                setCountries(temp_countries);
                if (temp_countries.length === 1) {
                    setFormData((formData) => ({
                        ...formData,
                        countryCode: temp_countries[0].phone_code + '_' + temp_countries[0].code
                    }));
                }
            }
            const fetchEarnQRCodeDetail = async () => {
                const response = await ReadLoyaltyEarnQRService('', params.token);
                if (response.qrs.length) {
                    setQRCodeDetail(response.qrs[0]);
                } else {
                    setQRCodeDetail({'status': 'failed'});
                }
            };
            fetchEarnQRCodeDetail();
            setLoading(false);
        })();
    }, [params.token]);

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <div className="qr-container-main">
                    <div className="wrapper-side form-container">
                        <div className="form-container">
                            <div className="logo-wrapper">
                                <img src={logoSm} alt="" />
                            </div>
                            {qrCodeDetail && qrCodeDetail.status === 'pending' ? (
                                <>
                                    <form onSubmit={handleSubmit} className="qr-form">
                                        <div className="form-group-dx">
                                            <label htmlFor="mobile">Please enter your valid mobile to get your points.</label>
                                            <div className="form-group-country-mobile">
                                                <div className="form-group-country">
                                                    <select id="countryCode" name="countryCode" required
                                                        value={formData.countryCode} onChange={handleInputChange}>
                                                        <option>Select Country</option>
                                                        {countries.map((country) => {
                                                            return (
                                                                <option key={country.id} value={country.phone_code + '_' + country.code}>
                                                                    +{country.phone_code} - {country.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group-mobile">
                                                    <input
                                                        type="tel"
                                                        id="mobile"
                                                        name="mobile"
                                                        value={formData.mobile}
                                                        placeholder="Enter mobile number"
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {formErrors.countryCode && (
                                            <p className="dx-lib input-error">{formErrors.countryCode}</p>
                                        )}
                                        {formErrors.mobile && (
                                            <p className="dx-lib input-error">{formErrors.mobile}</p>
                                        )}
                                        {formErrors.otherError && (
                                            <p className="dx-lib input-error">{formErrors.otherError}</p>
                                        )}
                                        {formErrors.otherArabicError && (
                                            <p className="dx-lib input-error">{formErrors.otherArabicError}</p>
                                        )}
                                        <button type="submit" className="submit-button dx-btn-primary" disabled={loading}>
                                            {loading ? "Getting Points..." : "Get Points"}
                                        </button>
                                    </form>
                                </>
                            ) : qrCodeDetail && (qrCodeDetail.status === 'failed' || qrCodeDetail.status === 'done') ? (
                                <div className="form-group-dx">
                                    <h1 className="invalid-msg">
                                        {messages.invalid_qr_code.english}
                                        <br/>
                                        {messages.invalid_qr_code.arabic}
                                    </h1>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EarnQRScan;