import React, { useEffect, useState } from "react";
import { BsChatLeftQuote } from "react-icons/bs";
import { PiCoinsLight } from "react-icons/pi";
import { RiUserSharedLine } from "react-icons/ri";
import QRCode from "react-qr-code";
import { Link, useOutletContext } from "react-router-dom";
import Popup from 'reactjs-popup';
import Layout from "../../components/common/Layout";
import Loading from "../../components/common/Loading";
import { ReadBusinessesService } from "../../services/business";
import { ReadCountriesService } from "../../services/general";
import { PushLoyaltyTransferPointsService, ReadLoyaltyPointsHistoryService, ReadLoyaltyPointsService } from "../../services/loyalty";
import "./dashboard.css";

const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        pointsToTransfer: 0.0,
        countryCode: "",
        receiverMobileNumber: ""
    });
    const [formErrors, setFormErrors] = useState({
        pointsToTransfer: "",
        countryCode: "",
        receiverMobileNumber: ""
    });
    const [userLoginDetails, setUserLoginDetails] = useState({});
    const [businesses, setBusinesses] = useState([]);
    const [countries, setCountries] = useState([]);
    const [currentLoyaltyPoints, setCurrentLoyaltyPoints] = useState(0);
    const [loyaltyHistory, setLoyaltyHistory] = useState([]);
    const [transferQRCode, setTransferQRCode] = useState('');
    const [openPointsTransferModal, setOpenPointsTransferModal] = useState(false);
    const closePointsTransferModal = () => setOpenPointsTransferModal(false);
    const [openQRModal, setOpenQRModal] = useState(false);
    const closeQRModal = () => setOpenQRModal(false);
    const [currentUser, tempUserLoginDetails] = useOutletContext();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "receiverMobileNumber" && value.startsWith("0")) {
            return;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClick = async (e) => {
        e.preventDefault();
        let pointsToTransferError = formData.pointsToTransfer > 0 ? "" : "Points to Transfer cannot be Blank";
        const countryCodeError = formData.countryCode ? "" : "Country Code cannot be Blank";
        const receiverMobileNumberError = formData.receiverMobileNumber ? "" : "Receiver Mobile Number cannot be Blank";
        if (formData.pointsToTransfer && currentLoyaltyPoints < formData.pointsToTransfer){
            pointsToTransferError = `You cannot transfer more than ${currentLoyaltyPoints} Points`;
        }
        setFormErrors({
            pointsToTransfer: pointsToTransferError,
            countryCode: countryCodeError,
            receiverMobileNumber: receiverMobileNumberError
        });
        if (!countryCodeError && !receiverMobileNumberError && !pointsToTransferError) {
            setLoading(true);
            let receiverMobileNumber = '+' + formData.countryCode + formData.receiverMobileNumber;
            let temp_point_transfer = await PushLoyaltyTransferPointsService(currentUser, receiverMobileNumber, formData.pointsToTransfer);
            if (temp_point_transfer.success) {
                if (temp_point_transfer.data.qr_code_chars){
                    setTransferQRCode(window.location.origin + '/qr-scan/' + temp_point_transfer.data.qr_code_chars);
                    setOpenQRModal(o => !o);
                }
            } else {
                if (temp_point_transfer.message) {
                    setFormErrors((prev) => ({ ...prev, pointsToTransfer: temp_point_transfer.message }));
                }
            }
            setLoading(false);
        }
    };

    const handleDownloadClick = () => {
        const svgData = new XMLSerializer().serializeToString(document.getElementById('qr-code'));
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));

        img.onload = function() {
            const padding = 20;
            canvas.width = img.width + padding * 2;
            canvas.height = img.height + padding * 2;
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, padding, padding);
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'qr-code.png';
            link.click();
        };
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            const fetchUserLoginDetails = () => {
                if (tempUserLoginDetails) {
                    setUserLoginDetails(tempUserLoginDetails);
                }
            };
            fetchUserLoginDetails();
            let temp_businesses = await ReadBusinessesService(currentUser, 'desc', 10, 0, '');
            if (temp_businesses.businesses) {
                setBusinesses(temp_businesses.businesses);
            }
            let temp_current_loyalty_points = await ReadLoyaltyPointsService(currentUser);
            if (temp_current_loyalty_points) {
                setCurrentLoyaltyPoints(temp_current_loyalty_points);
            }
            let temp_loyalty_history = await ReadLoyaltyPointsHistoryService(currentUser, 'desc', 5, 0, '');
            if (temp_loyalty_history.point_history) {
                setLoyaltyHistory(temp_loyalty_history.point_history);
            }
            let temp_countries = await ReadCountriesService();
            if (temp_countries) {
                setCountries(temp_countries);
                if (temp_countries.length === 1) {
                    setFormData((formData) => ({
                        ...formData,
                        countryCode: temp_countries[0].phone_code
                    }));
                }
            }
            setLoading(false);
        })();
    }, [currentUser, tempUserLoginDetails]);

    return (
        <Layout title="Dashboard" userLoginDetails={userLoginDetails}>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboard-grid-container">
                    <div className="grid-child list-wrapper-1">
                        <h3 className="dx-table-title">
                            Business List{" "}
                            <Link to="/dashboard/businesses" className="btn-link view-all">
                                View All
                            </Link>
                        </h3>
                        <div className="table-desc-text">Recent 10 businesses.</div>
                        <div className="dx-table dashboard-table">
                            <div className="dx-table-responsive">
                                <div className="dx-tr table-header">
                                    <div className="dx-th">Business name</div>
                                </div>
                                {businesses.map((business) => {
                                    return (
                                        <div key={business.id} className="dx-tr table-data">
                                            <div className="dx-td">{business.name}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="grid-child list-wrapper-2">
                        <h3 className="dx-table-title">Current Rewards Points</h3>
                        <div className="points-wrapper">
                            <PiCoinsLight />
                            {currentLoyaltyPoints} &nbsp;&nbsp;
                            {currentLoyaltyPoints > 0 ? (
                            <>
                                <RiUserSharedLine style={{cursor: "pointer"}} title="Share Points"
                                    onClick={() => setOpenPointsTransferModal(o => !o)}/>
                                <Popup open={openPointsTransferModal} onClose={closePointsTransferModal}>
                                    <div className="modal">
                                        <button className="close" onClick={closePointsTransferModal}> &times; </button>
                                        <div className="header"><RiUserSharedLine/> Share Points</div>
                                        <div className="content">
                                            <div className="form-group-dx">
                                                <label>
                                                    <b>Points to Transfer:</b>
                                                </label>
                                                <input type="number" id="pointsToTransfer" name="pointsToTransfer"
                                                    value={formData.pointsToTransfer} min="0" max={currentLoyaltyPoints} step="0.01"
                                                    onChange={handleInputChange}/>
                                            </div>
                                            {formErrors.pointsToTransfer && (
                                                <p className="dx-lib input-error">{formErrors.pointsToTransfer}</p>
                                            )}
                                            <div className="form-group-dx form-group-country-mobile">
                                                <div className="form-group-country">
                                                    <select id="countryCode" name="countryCode" required
                                                        value={formData.countryCode} onChange={handleInputChange}>
                                                        <option>Select Country</option>
                                                        {countries.map((country) => {
                                                            return (
                                                                <option key={country.id} value={country.phone_code}>
                                                                    +{country.phone_code} - {country.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="form-group-mobile">
                                                    <input type="tel" id="receiverMobileNumber" name="receiverMobileNumber"
                                                        value={formData.receiverMobileNumber}
                                                        placeholder="Enter Receiver mobile number"
                                                        onChange={handleInputChange}/>
                                                </div>
                                            </div>
                                            {formErrors.countryCode && (
                                                <p className="dx-lib input-error">{formErrors.countryCode}</p>
                                            )}
                                            {formErrors.receiverMobileNumber && (
                                                <p className="dx-lib input-error">{formErrors.receiverMobileNumber}</p>
                                            )}
                                        </div>
                                        <div className="actions">
                                            <button className="submit-button dx-btn-primary" disabled={loading} onClick={handleClick}>
                                                {loading ? "Generating QR..." : "Generate QR"}
                                            </button>
                                        </div>
                                    </div>
                                </Popup>
                            </> ) : ''}
                            <Popup open={openQRModal} onClose={closeQRModal}>
                                <div className="modal qrmodal">
                                    <button className="close" onClick={closeQRModal}> &times; </button>
                                    <div className="content">
                                        {transferQRCode && (
                                            <>
                                                <QRCode id="qr-code" value={transferQRCode}/>
                                                <button className="submit-button dx-btn-primary" onClick={handleDownloadClick}>
                                                    Download QR
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Popup>
                        </div>
                    </div>
                    <div className="grid-child list-wrapper-3">
                        <h3 className="dx-table-title">
                            Transaction History
                            <Link
                                to="/dashboard/rewards-history"
                                className="btn-link view-all"
                            >
                                View All
                            </Link>
                        </h3>
                        <div className="table-desc-text">Last 5 transactions</div>
                        <div className="dx-table dashboard-table">
                            <div className="dx-table-responsive">
                                <div className="dx-tr table-header">
                                    <div className="dx-th">Date</div>
                                    <div className="dx-th">Type</div>
                                    <div className="dx-th">Business</div>
                                    <div className="dx-th">Points</div>
                                    <div className="dx-th"></div>
                                </div>
                                {loyaltyHistory.map((history) => {
                                    return (
                                        <div key={history.id} className="dx-tr table-data">
                                            <div className="dx-td">{history.create_date}</div>
                                            <div className="dx-td">{history.type === 'credit' ? 'Credit' : 'Debit'}</div>
                                            <div className="dx-td">{history.business}</div>
                                            <div className="dx-td">{history.points}</div>
                                            <div className="dx-td">
                                                {history.access_token && !history.is_offer_given && !history.is_reward_record ?
                                                    <Link to={`/dashboard/rewards-history/comments/${history.access_token}`}
                                                        className="btn-link comments-btn" title="Comments">
                                                        <BsChatLeftQuote />
                                                    </Link> : ''}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="grid-child list-wrapper-4">
                        <div style={{height: "auto",
                                margin: "0 auto",
                                maxWidth: "200px",
                                width: "100%",
                                background: "#fff",
                                padding: 10,
                                borderRadius: 10,
                            }}>
                            {userLoginDetails && userLoginDetails.mobile ? (
                                <QRCode
                                    size={656}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={userLoginDetails.mobile}
                                    viewBox={`0 0 256 256`}
                                />
                            ) : ''}
                        </div>
                        <p className="qr-sub-title">
                            {userLoginDetails && userLoginDetails.name ? userLoginDetails.name : ''}
                        </p>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Dashboard;