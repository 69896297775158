import React, { useEffect, useState } from "react";
import logoSm from "../../assets/icons/logo-1.jpg";
import Loading from "../../components/common/Loading";
import "./thankyou.css";
var Messages = require('./ThankYouMessages.json');

const ThankYou = () => {
    const [messages, setMessages] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            setMessages(Messages);
            setLoading(false);
        })();
    }, []);

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <div className="thank-you-container-main">
                    <div className="wrapper-side form-container">
                        <div className="form-container">
                            <div className="logo-wrapper">
                                <img src={logoSm} alt="" />
                            </div>
                            <div className="form-group-dx">
                                {messages.thank_you ? <h1 className="invalid-msg">
                                    {messages.thank_you.english}
                                    <br/>
                                    {messages.thank_you.arabic}
                                </h1> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ThankYou;