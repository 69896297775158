import React, { useEffect, useState } from "react";
import { BsChatLeftQuote } from "react-icons/bs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link, useOutletContext } from "react-router-dom";
import Layout from "../../components/common/Layout";
import Loading from "../../components/common/Loading";
import { ReadLoyaltyPointsHistoryService } from "../../services/loyalty";

const ITEMS_PER_PAGE = 5;

const LoyaltyHistory = () => {
    const [loading, setLoading] = useState(false);
    const [userLoginDetails, setUserLoginDetails] = useState({});
    const [loyaltyHistory, setLoyaltyHistory] = useState([]);
    const [totalLoyaltyHistories, setTotalLoyaltyHistories] = useState(0);
    const [totalLoyaltyHistoriesCount, setTotalLoyaltyHistoriesCount] = useState(0);
    const [loyaltyHistorySearchValue, setLoyaltyHistorySearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [currentUser, tempUserLoginDetails] = useOutletContext();

    const fetchLoyaltyHistory = async (page) => {
        setLoading(true);
        const response = await ReadLoyaltyPointsHistoryService(currentUser, 'asc', ITEMS_PER_PAGE, page, loyaltyHistorySearchValue);
        if (response.point_history) {
            setLoyaltyHistory(response.point_history);
        }
        setTotalLoyaltyHistories(Math.ceil(response.total_point_history / ITEMS_PER_PAGE));
        setTotalLoyaltyHistoriesCount(response.total_point_history);
        setLoading(false);
    };

    const handlePerRowsChange = async (newPerPage) => {
        fetchLoyaltyHistory(newPerPage);
        setCurrentPage(newPerPage);
    };

    const setLoyaltyHistorySearchValueCallback = async (e) => {
        setLoyaltyHistorySearchValue(e.target.value);
    }

    const searchLoyaltyHistory = async (e) => {
        e.preventDefault();
        fetchLoyaltyHistory(currentPage);
    }

    const clearSearchedLoyaltyHistory = async (e) => {
        e.preventDefault();
        fetchLoyaltyHistory(currentPage);
    }

    useEffect(() => {
        (async () => {
            const fetchLoyaltyHistory = async () => {
                setLoading(true);
                const response = await ReadLoyaltyPointsHistoryService(currentUser, 'asc', ITEMS_PER_PAGE, 0, '');
                if (response.point_history) {
                    setLoyaltyHistory(response.point_history);
                }
                setTotalLoyaltyHistories(Math.ceil(response.total_point_history / ITEMS_PER_PAGE));
                setTotalLoyaltyHistoriesCount(response.total_point_history);
                setLoading(false);
            };
            fetchLoyaltyHistory();
            const fetchUserLoginDetails = () => {
                if (tempUserLoginDetails){
                    setUserLoginDetails(tempUserLoginDetails);
                }
            };
            fetchUserLoginDetails();
        })();
    }, [currentUser, tempUserLoginDetails]);

    const tableHead = ["Date", "Type", "Business", "Points", "Amount", "Remarks", ""];

    return (
        <Layout title="Current Rewards Points and Transactions History" userLoginDetails={userLoginDetails}>
            {loading ? (
                <Loading />
            ) : (
                <div className="business-table-container">
                    <div className="searchbox">
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search with Remarks ..."
                            value={loyaltyHistorySearchValue}
                            onChange={setLoyaltyHistorySearchValueCallback}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    searchLoyaltyHistory(e)
                                }
                            }}
                        />
                        <a
                            href="/#"
                            onClick={(e) =>
                                searchLoyaltyHistory(e)
                            }>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none">
                                <path
                                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                    stroke="#646975"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M20.9984 20.9984L16.6484 16.6484"
                                    stroke="#646975"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </a>
                        {loyaltyHistorySearchValue ? (
                            <a
                                href="/#"
                                className="close-icon"
                                onClick={(e) =>
                                    clearSearchedLoyaltyHistory(e)
                                }>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="28"
                                    height="28"
                                    viewBox="0 0 28 28"
                                    fill="none">
                                    <path
                                        d="M26 2L2 26"
                                        stroke="#3A4750"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M2 2L26 26"
                                        stroke="#3A4750"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </a>) : ("")
                        }
                    </div>
                    <table className="business-table">
                        <thead className="table-header">
                            <tr className="header-row">
                                {
                                    tableHead.map((names, id) => {
                                        return <th key={id} className="header-cell">{names}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {loyaltyHistory.map((item) => (
                                <tr key={item.id} className="table-row">
                                    <td className="table-cell">{item.create_date}</td>
                                    <td className="table-cell">{item.type === 'credit' ? 'Credit' : 'Debit'}</td>
                                    <td className="table-cell">{item.business}</td>
                                    <td className="table-cell">{item.points}</td>
                                    <td className="table-cell">{item.amount}</td>
                                    <td className="table-cell">{item.remarks}</td>
                                    <td className="table-cell">
                                        {item.access_token && !item.is_offer_given && !item.is_reward_record ?
                                            <Link to={`/dashboard/rewards-history/comments/${item.access_token}`}
                                                className="btn-link comments-btn" title="Comments">
                                                <BsChatLeftQuote />
                                            </Link> : ''}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {totalLoyaltyHistoriesCount > ITEMS_PER_PAGE ? (
                        <div className="pagination-controls">
                            <button
                                className="pagination-button"
                                onClick={() =>
                                    handlePerRowsChange(currentPage - 1)
                                }
                                disabled={currentPage === 0}
                            >
                                <IoIosArrowBack />
                            </button>
                            <span className="pagination-info">
                                Page {currentPage + 1} of {totalLoyaltyHistories}
                            </span>
                            <button
                                className="pagination-button"
                                onClick={() =>
                                    handlePerRowsChange(currentPage + 1)
                                }
                                disabled={currentPage + 1 === totalLoyaltyHistories}
                            >
                                <IoIosArrowForward />
                            </button>
                        </div>
                    ) : ""}
                </div>
            )}
        </Layout>
    );
};

export default LoyaltyHistory;