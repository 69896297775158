import axios from "axios";
import config from "../config";

export const UserLoginService = async (mobile, password) => {
    var user_login_response = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.post("user/login/", {
        "mobile": mobile,
        "password": password
    },
    {
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then((response) => {
        user_login_response = response.data;
    })
    .catch((error) => {
        if ("response" in error) {
            user_login_response = error.response.data;
        } else {
            user_login_response = { 'success': false, 'message': 'Something went wrong, please try again' };
        }
    });
    return await user_login_response;
};

export const UserLoginDetailsService = async (currentUser) => {
    var user_details = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.get("user/profile/", {
        headers: {
            "authorization": "Bearer " + currentUser,
            "Content-Type": "application/json"
        },
    })
    .then((response) => {
        if (response.data.success) {
            user_details = response.data.data;
        }
    });
    return await user_details;
};

export const UpdateUserLoginDetailsService = async (currentUser, data) => {
    var user_details = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.put("user/profile/", data,
    {
        headers: {
            "authorization": "Bearer " + currentUser,
            "Content-Type": "application/json"
        }
    })
    .then((response) => {
        if (response.data.success) {
            user_details = response.data.data;
        }
    });
    return await user_details;
};

export const GenerateOTPResetPasswordService = async (mobile) => {
    var generate_otp_response = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.get("user/generate/otp/", {
        params: {
            'mobile': mobile
        },
    })
    .then((response) => {
        if (response) {
            generate_otp_response = response.data;
        }
    })
    .catch((error) => {
        if (error){
            generate_otp_response = error.response.data;
        }
    });
    return await generate_otp_response;
};

export const ValidateOTPResetPasswordService = async (mobile, otp, password, isValidateOTP) => {
    var reset_password_response = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.put("user/validate/otp/", {
        'mobile': mobile,
        'otp': otp,
        'password': password,
        'is_validate_otp': isValidateOTP
    })
    .then((response) => {
        if (response.data) {
            reset_password_response = response.data;
        }
    })
    .catch((error) => {
        if (error){
            reset_password_response = error.response.data;
        }
    });
    return await reset_password_response;
};