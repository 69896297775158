import axios from "axios";
import config from "../config";

export const ReadLoyaltyPointsService = async (currentUser) => {
    var loyalty_points = 0;
    axios.defaults.baseURL = config.servers["base"];
    await axios.get("user/loyalty/points/", {
        headers: {
            "authorization" : "Bearer " + currentUser,
            "Content-Type": "application/json"
        },
    })
    .then((response) => {
        if (response.data.success){
            loyalty_points = response.data.data.points;
        }
    });
    return await loyalty_points;
};

export const ReadLoyaltyPointsHistoryService = async (currentUser, direction, limit, page, search) => {
    var loyalty_history_data = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.get("user/loyalty/history/", {
        params: {
            'direction': direction,
            'limit': limit,
            'page': page,
            'search': search
        },
        headers: {
            "authorization" : "Bearer " + currentUser,
            "Content-Type": "application/json"
        },
    })
    .then((response) => {
        if (response.data.success){
            loyalty_history_data['point_history'] = response.data.data.point_history;
            loyalty_history_data['total_point_history'] = response.data.data.total_point_history;
        }
    });
    return await loyalty_history_data;
};

export const ReadLoyaltyPointsHistoryCommentsService = async (currentUser, historyAccessToken) => {
    var loyalty_history_comments_data = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.get("user/loyalty/history/review/", {
        params: {
            'history_access_token': historyAccessToken
        },
        headers: {
            "authorization" : "Bearer " + currentUser,
            "Content-Type": "application/json"
        },
    })
    .then((response) => {
        if (response.data.success){
            loyalty_history_comments_data = response.data.data;
        }
    });
    return await loyalty_history_comments_data;
};

export const AddLoyaltyPointsHistoryCommentsService = async (currentUser, historyAccessToken, feedbackStars, feedbackComment) => {
    var comment_response = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.put("user/loyalty/history/review/", {
        'history_access_token': historyAccessToken,
        'feedback_stars': feedbackStars,
        'feedback_comment': feedbackComment
    },
    {
        headers: {
            "authorization": "Bearer " + currentUser,
            "Content-Type": "application/json"
        }
    })
    .then((response) => {
        comment_response = response.data;
    })
    .catch((error) => {
        comment_response = error.response.data;
    });
    return await comment_response;
};

export const ReadLoyaltyTransferPointsService = async (currentUser, transfer_history_token, direction, limit, page, search) => {
    var points_transfer_data = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.get("user/loyalty/transfer/points/", {
        params: {
            'transfer_history_token': transfer_history_token,
            'direction': direction,
            'limit': limit,
            'page': page,
            'search': search
        },
        headers: {
            "authorization" : "Bearer " + currentUser,
            "Content-Type": "application/json"
        },
    })
    .then((response) => {
        if (response.data.success){
            points_transfer_data['histories'] = response.data.data.histories;
            points_transfer_data['total_histories'] = response.data.data.total_histories;
        }
    });
    return await points_transfer_data;
};

export const PushLoyaltyTransferPointsService = async (currentUser, receiverMobileNumber, pointsToTransfer) => {
    var points_transfer_data = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.post("user/loyalty/transfer/points/", {
        'receiver_mobile_number': receiverMobileNumber,
        'points_to_transfer': pointsToTransfer
    },
    {
        headers: {
            "authorization" : "Bearer " + currentUser,
            "Content-Type": "application/json"
        }
    })
    .then((response) => {
        points_transfer_data = response.data;
    })
    .catch((error) => {
        points_transfer_data = error.response.data;
    });
    return await points_transfer_data;
};

export const ValidateLoyaltyTransferPointsService = async (transfer_history_token, country_code,
        receiver_mobile_number, is_skip_customer_checking) => {
    var validate_response = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.put("user/loyalty/transfer/points/", {
        'transfer_history_token': transfer_history_token,
        'country_code': country_code,
        'receiver_mobile_number': receiver_mobile_number,
        'is_skip_customer_checking': is_skip_customer_checking
    },
    {
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then((response) => {
        validate_response = response.data;
    })
    .catch((error) => {
        validate_response = error.response.data;
    });
    return await validate_response;
};

export const CancelLoyaltyTransferPointsService = async (currentUser, transfer_history_token) => {
    var cancel_response = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.put("user/loyalty/transfer/points/cancel/", {
        'transfer_history_token': transfer_history_token
    },
    {
        headers: {
            "authorization" : "Bearer " + currentUser,
            "Content-Type": "application/json"
        }
    })
    .then((response) => {
        cancel_response = response.data;
    })
    .catch((error) => {
        cancel_response = error.response.data;
    });
    return await cancel_response;
};

export const ReadLoyaltyEarnQRService = async (currentUser, token) => {
    var earn_qr_data = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.get("user/loyalty/earn/qr/", {
        params: {
            'token': token
        },
        headers: {
            "authorization" : "Bearer " + currentUser,
            "Content-Type": "application/json"
        },
    })
    .then((response) => {
        if (response.data.success){
            earn_qr_data['qrs'] = response.data.data.qrs;
            earn_qr_data['total_qrs'] = response.data.data.total_qrs;
        }
    });
    return await earn_qr_data;
};

export const ValidateLoyaltyEarnQRService = async (token, country_code, receiver_mobile_number, is_skip_customer_checking) => {
    var validate_response = {};
    axios.defaults.baseURL = config.servers["base"];
    await axios.put("user/loyalty/earn/qr/", {
        'token': token,
        'country_code': country_code,
        'receiver_mobile_number': receiver_mobile_number,
        'is_skip_customer_checking': is_skip_customer_checking
    },
    {
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then((response) => {
        validate_response = response.data;
    })
    .catch((error) => {
        validate_response = error.response.data;
    });
    return await validate_response;
};